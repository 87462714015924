import "magnific-popup";
import 'owl.carousel';
import './vendor/owl-carousel/owl.carousel.min.css';

// import './vendor/photoswipe/photoswipe.min.css';
// import './vendor/photoswipe/default-skin/default-skin.min.css';
// import './vendor/photoswipe/photoswipe.min';
// import './vendor/photoswipe/photoswipe-ui-default.min';

import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';

import { gsap } from "gsap";
window.gsap = gsap;

/* ===== LazyLoad ===== */
import LazyLoad from "vanilla-lazyload";
let rrr = new LazyLoad({elements_selector: ".js-lazy"});
console.log('--- LazyLoad');
console.log(rrr);


import "lightgallery/dist/css/lightgallery.css";
import "lightgallery";
$('[rel="mediaportal"]').lightGallery({
    // loop: false,
    thumbnail: false,
    animateThumb: false,
    showThumbByDefault: false,
    selector: '.js-lightgallery',
    mode: 'lg-fade',
    fullScreen: false,
    download: false,
    share: false,
    zoom: true,
    actualSize: false,
    autoplay: false
});

import "sharer.js";
import './js/main.js';